import React from "react";
import { graphql } from "gatsby";
import { Styling } from "../../styles/pages/shop-category.styled";
import HeroImageTitle from "../../components/reusable/HeroImageTitle";
import CategoryFilters from "../../components/reusable/CategoryFilters";
import ProductsGridViewMore from "../../components/reusable/ProductsGridViewMore";
import Seo from "../../components/global/seo";
import ProductSlider from "../../components/reusable/ProductSlider";

const SamplePacks = ({ data }) => {
  //console.log(data.products.nodes)
  return (
    <Styling>
      <Seo
        title={data.wpPage.seo.title}
        description={data.wpPage.seo.metaDesc}
      />
      <HeroImageTitle
        title="Sample Packs"
        hero={data?.wpPage?.acf_hero?.heroImage?.localFile}
      />
      <ProductSlider
        products={data.sliderProducts.nodes}
        title="Best Sellers"
        link="/shop/sample-packs/"
        hasTitle={true}
        hasArrows={true}
        hasViewAll={true}
        showProgress={false}
      />
      <CategoryFilters filters={data.filters.nodes} base="sample-packs" />
      <ProductsGridViewMore
        products={data.products.nodes}
        title="Sample Packs"
      />
    </Styling>
  );
};

export default SamplePacks;

export const query = graphql`
  {
    wpPage(title: { eq: "Sample Packs" }) {
      id
      seo {
        metaDesc
        title
      }
      acf_hero {
        heroImage {
          localFile {
            childImageSharp {
              gatsbyImageData(
                width: 800
                placeholder: BLURRED
                formats: [AUTO, WEBP]
              )
            }
          }
        }
      }
    }
    filters: allWpSamplePackCategory {
      nodes {
        slug
        name
      }
    }
    products: allWpProduct(
      filter: {
        productCategories: {
          nodes: { elemMatch: { slug: { eq: "sample-packs" } } }
        }
      }
      sort: { fields: date, order: DESC }
    ) {
      nodes {
        id
        title
        slug
        acf_product_info {
          samplePacks {
            price
            images {
              image {
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      width: 800
                      placeholder: BLURRED
                      formats: [AUTO, WEBP]
                    )
                  }
                }
              }
            }
          }
        }
      }
    }
    sliderProducts: allWpProduct(
      filter: {
        productCategories: {
          nodes: { elemMatch: { name: { eq: "Sample Packs" } } }
        }
        acf_product_info: {
          samplePacks: { addToMostPopularSlider: { eq: true } }
        }
      }
      sort: { order: DESC, fields: date }
    ) {
      nodes {
        id
        title
        slug
        acf_product_info {
          samplePacks {
            price
            images {
              image {
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      width: 800
                      placeholder: BLURRED
                      formats: [AUTO, WEBP]
                    )
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
